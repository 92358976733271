import Plyr from "plyr";

export const useVideoPlayer = () => {
  const initializePlayer = (selector) => {
    const player = new Plyr(selector, {
      controls: [
        "play",
        "progress",
        "current-time",
        "mute",
        "volume",
        "fullscreen",
      ],
    });

    player.source = {
      type: "video",
      title: "About Us",
      poster:
        "https://s3.us-east-1.amazonaws.com/static.bigleaphealth.com/mrhs/Poster.jpg",
      sources: [
        {
          src: "https://s3.us-east-1.amazonaws.com/static.bigleaphealth.com/mrhs/about-us.webm",
          type: "video/webm",
        },
        {
          src: "https://s3.us-east-1.amazonaws.com/static.bigleaphealth.com/mrhs/about-us.mp4",
          type: "video/mp4",
        },
      ],
    };

    return player;
  };

  return {
    initializePlayer,
  };
};
